var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "content-class": "rounded-lg elevation-2",
      "value": _vm.value,
      "persistent": "",
      "width": "1000"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.$emit('close');
      }
    }
  }, [_c('v-card', {
    attrs: {
      "max-height": "90vh"
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('administration.tasks.title')) + " "), _c('v-spacer'), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "color": "primary",
      "elevation": "0",
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.state.isAddEditTaskDialogOpen = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('administration.tasks.form.add.title')) + " ")])], 1), _c('v-card-text', {
    staticClass: "my-5 content__height"
  }, [_vm.tasks.length ? _c('v-simple-table', [_c('thead', [_c('tr', [_c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("administration.tasks.table.title")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("administration.tasks.table.type")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("administration.tasks.table.dueTo")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("administration.tasks.table.content")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("administration.tasks.table.assignedTo")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("administration.tasks.table.children")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("administration.tasks.table.solved")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  })])]), _c('tbody', _vm._l(_vm.tasks, function (task) {
    return _c('tr', {
      key: task.id,
      staticClass: "table-row-color"
    }, [_c('td', [_vm._v(" " + _vm._s(task.title) + " ")]), _c('td', [_vm._v(" " + _vm._s(task.type) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(task.dueTo)) + " ")]), _c('td', [_vm._v(" " + _vm._s(task.content) + " ")]), _c('td', [_vm._v(" " + _vm._s(task.assignedTo && task.assignedTo.name) + " ")]), _c('td', [_vm._v(" " + _vm._s(task.children.length) + " ")]), _c('td', [_c('DotIndicator', {
      attrs: {
        "color": "".concat(task.solved ? 'green' : 'red')
      }
    })], 1), _c('td', [_c('v-btn', {
      staticClass: "mx-2",
      attrs: {
        "icon": "",
        "x-small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.listeners.onClickEdit(task);
        }
      }
    }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiPencil) + " ")])], 1), _c('v-menu', {
      attrs: {
        "offset-y": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
              attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            attrs: {
              "icon": "",
              "x-small": ""
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiDelete) + " ")])], 1)];
        }
      }], null, true)
    }, [_c('v-card', [_c('v-card-title', {
      staticClass: "text-subtitle-1"
    }, [_vm._v(" " + _vm._s(_vm.$t('administration.tasks.delete.text')) + " ")]), _c('v-card-actions', [_c('v-btn', {
      staticClass: "rounded-lg",
      attrs: {
        "small": "",
        "outlined": "",
        "elevation": "0"
      },
      on: {
        "click": function click($event) {
          return _vm.listeners.onClickDelete(task);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('form.yes')) + " ")]), _c('v-spacer'), _c('v-btn', {
      staticClass: "rounded-lg",
      attrs: {
        "small": "",
        "outlined": "",
        "elevation": "0"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('form.no')) + " ")])], 1)], 1)], 1)], 1)]);
  }), 0)]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('administration.tasks.noTasks')) + " ")])], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.close')) + " ")])], 1)], 1), _vm.state.isAddEditTaskDialogOpen ? _c('AddEditTaskDialog', {
    attrs: {
      "entity-name": _vm.entityName,
      "entity-id": _vm.entityId,
      "is-edit-mode": _vm.state.isEditMode,
      "task-to-edit": _vm.state.taskToEdit
    },
    on: {
      "reload-tasks": function reloadTasks($event) {
        return _vm.$emit('reload-tasks');
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditTaskDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditTaskDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditTaskDialogOpen", $$v);
      },
      expression: "state.isAddEditTaskDialogOpen"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }