
import { defineComponent, PropType, reactive, ref } from '@vue/composition-api'
import { mdiPencil, mdiDelete } from '@mdi/js'

import DotIndicator from '@/components/common/CommonDotIndicator.vue'

import { useDeleteTask, useGetTask } from '@/api/task'

import { handleError } from '@/utils/handleError'
import { dateDashNotationToDot } from '@/utils/convertDate'

import { TaskOutput, TaskId } from '@/api/types/task'
import { SupplierId } from '@/api/types/supplier'
import { ContractId } from '@/api/types/contract'
import { AssignmentId } from '@/api/types/assignment'
import { PersonId } from '@/api/types/person'
import { EquipmentId } from '@/api/types/equipment'

export default defineComponent({
  name: 'CommonTasksDialog',
  components: {
    DotIndicator,
    AddEditTaskDialog: () => import('@/components/common/AddEditTaskDialog.vue'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    tasks: {
      type: Array as PropType<TaskOutput[]>,
      required: true,
    },
    entityName: {
      type: String,
      required: true,
    },
    entityId: {
      type: Number as PropType<SupplierId | ContractId | AssignmentId | PersonId | TaskId | EquipmentId>,
      default: null,
    },
  },
  setup: (_, { emit }) => {
    const isAddEditTaskDialogOpen = ref(false)

    function onCloseAddEditTaskDialog(): void {
      isAddEditTaskDialogOpen.value = false

      isEditMode.value = false

      taskToEdit.value = null
    }

    const { deleteTask } = useDeleteTask()

    async function onClickDelete(task: TaskOutput): Promise<void> {
      try {
        await deleteTask(task.id)

        emit('reload-notes')
      } catch (error: unknown) {
        handleError(error)
      }

      emit('reload-tasks')
    }

    const isEditMode = ref(false)

    const taskToEdit = ref<TaskOutput | null>(null)

    const { getTask } = useGetTask()

    async function onClickEdit(task: TaskOutput): Promise<void> {
      isEditMode.value = true

      isAddEditTaskDialogOpen.value = true

      taskToEdit.value = await getTask(task.id)
    }

    return reactive({
      icons: {
        mdiPencil,
        mdiDelete,
      },
      state: {
        isAddEditTaskDialogOpen,

        isEditMode,
        taskToEdit,
      },
      functions: {
        dateDashNotationToDot,
      },
      listeners: {
        onCloseAddEditTaskDialog,

        onClickDelete,

        onClickEdit,
      },
    })
  },
})
